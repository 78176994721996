#main-content {
  margin-bottom: 62px;
}

.main-content-maximized {
  padding-left: 3.5rem;
  padding-top: 0.05rem;
  transition: padding-left 0.3s ease;
  overflow-x: hidden;
}

.main-content-minimized {
  padding-top: 0.05rem;
  padding-left: 3.5rem;
  transition: padding-left 0.3s ease;
  overflow-x: hidden;
}

.content-minimized {
  padding-top: 64px;
  padding-left: 150px;
  padding-right: 150px;
  transition: padding-left 0.3s ease;
  overflow-x: hidden;
}

.main-content-label {
  color: rgb(89, 84, 164);
  font-weight: 700;
  font-size: 30px;
  line-height: 1.5rem;
}

/* CSS for the row */
.row {
  display: flex;
  align-items: center;
}

/* CSS for the columns within the row */
.column {
  display: flex;
  flex-direction: column;
}

.button {
  background-color: #5954a4;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.5s ease;
}

.white.button:hover {
  background-color: #fff;
  color: #04254e;
}

.blue.button:hover {
  background-color: #04254e;
}

.gradient-text,
.gradient-text-big {
  background: linear-gradient(to right, #8ad5eb 0%, #5954a4 100%);
  background-clip: text;
  color: transparent;
  display: inline;
  font-family: Inter, Arial, Arial;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -1px;
}

.gradient-text-big {
  font-size: 30px;
}

.none-gradient {
  background: none;
  color: black;
  font-family: Inter, Arial, Arial;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -1px;
}

/*
* Used in the ff.
* Help.jsx 
* Contact.jsx
*/
.support-options.column {
  display: block;
  max-width: 350px;
}

.support-options-div {
  transition: background-color 0.3s;
  color: #5954a4;
}

.support-options-div:hover {
  background-color: #5954a4;
  color: #fff;
  cursor: pointer;
}

.support-options-div:focus {
  background-color: #5954a4;
  color: #fff;
  cursor: pointer;
}

.support-content.column {
  display: block;
  width: 100%;
  margin-right: 20px;
}

.search-div {
  margin-bottom: 10px;
}

.view-label {
  font-size: 20px;
  margin-left: 10px;
}

.center {
  text-align: center;
}

.center svg {
  display: block;
  margin: auto;
}

.link {
  cursor: pointer;
}

/* Styles for the loader overlay */
.loader-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8);
  display: flex;
  justify-content: center;
  align-items: center !important;
  z-index: 1;
  display: none;
  /* Initially hide the overlay */
}

/* Styles for the loader spinner (you can replace this with your loader animation) */
.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #5954a4;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

/* Keyframe animation for the loader spinner */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loader-container {
  position: relative;
}

/* Styles for the error message */
.error-message {
  color: #dd59c3;
  margin-top: 5px;
  font-size: 13px;
}

.center {
  text-align: center;
}